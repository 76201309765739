nav {
  height: 80px;
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #101010;
  z-index: 1;
}

.navbar__container {
  width: 100%;
  max-width: 1600px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  align-items: center;
  margin: 0 auto;
}

.navbar__logo {
  object-fit: contain;
  width: 50px;
  border-radius: 100%;
}

.nav__half {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav__title {
  margin-left: 20px;
  margin-right: 5px;
  color: #7dbad2;
  position: relative;
  text-shadow: 0px 0px 8px rgba(125, 186, 210, 0.5);
  margin-bottom: 10px;
}

.nav__title:after {
  content: "";
  position: absolute;
  width: 102%;
  height: 3.5px;
  background-color: #7dbad2;
  box-shadow: 0px 0px 5px rgba(125, 186, 210, 0.5);
  border-radius: 9999px;
  bottom: -5px;
  left: 0;
  transition: all 500ms ease;
}

.nav__title:hover:after {
  bottom: -7.5px;
}

.nav__list {
  display: flex;
  gap: 20px;
}

.nav__button {
  box-shadow: 0px 0px 8px rgba(125, 186, 210, 0.1);
  text-shadow: 0px 0px 8px rgba(125, 186, 210, 0.5);
}

.nav__button:hover {
  box-shadow: 0px 0px 8px rgba(125, 186, 210, 0.5);
  text-shadow: 0px 0px 8px rgba(125, 186, 210, 0.75);
}

.nav__button--text {
  font-size: 16px;
}
