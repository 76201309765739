.infoMenu {
  position: absolute;
  backdrop-filter: blur(10px) ;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
  width: fit-content;
  height: fit-content;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  padding: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.infoMenu__option {
  object-fit: contain;
  width: 100px;
  transition: all 300ms ease;
}

.infoMenu__option:hover {
    filter: brightness(1.2);
    transform: scale(1.05);
}
.infoMenu__BigText {
  color: #7dbad2;
  text-align: center;
  font-size: 4rem;
  margin-top: 20px;
  text-shadow: 0px 0px 10px #7dbad2;
  max-width: 100rem;
  letter-spacing: 2px;
  line-height: 65px;
  animation: fadeIn 0.85s ease-in backwards;
}

.infoMenu__para {
  color: #7dbad2;
  text-align: center;
  font-size: 2rem;
  margin-top: 20px;
  text-shadow: 0px 0px 10px #1a1a1a;
  max-width: 100rem;
  letter-spacing: 2px;
  line-height: 30px;
  animation: fadeIn 0.85s ease-in backwards;
}
.infoMenu__buttons{
  display: flex;
  gap: 5px;

}
.close-button {
  background-color: rgb(141, 89, 89);
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: rgb(144, 77, 77); /* Change color on hover */
}