* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

body {
  background-color: #242424;
}

.headerStyle {
  animation: onLoadAnimation 1.5s 0s backwards;
}

@keyframes onLoadAnimation {
  0% {
    width: 0px;
    height: 0px;
    border-radius: 9999px;
    opacity: 0;
    box-shadow: 0px 0px 0px #000;
  }
  5% {
    width: 0px;
    height: 0px;
    border-radius: 9999px;
    opacity: 0;
  }

  20% {
    width: 50px;
    height: 50px;
    border-radius: 9999px;
    opacity: 1;
  }

  30% {
    width: 50px;
    height: 50px;
    border-radius: 100px;
    opacity: 1;
  }
  70% {
    box-shadow: 0px 0px 0px #000;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 2268px) {
  .products__list {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .products__header{
    max-height: 100% !important;
  }
  .prow{
    width: 100% !important;
  }


}
  
@media screen and (max-width: 1068px), 
screen and (max-height: 1075px){

  .nav__item {
    display: none !important;
  }

  .nav__mobile--item {
    display: block !important;
  }

  .nav__mobile--button {
    display: block !important;
  }

  .h__half--right {
    display: none !important;
  }
  .h__half--left {
    width: 100% !important;
    align-items: center !important;
  }
  .home__title {
    font-size: 2rem !important;
  }

  .home__para {
    font-size: 1.5rem !important;
    margin-top: 30px !important;
  }

  .home__header {
    margin-bottom: 0px !important;
  }

  .home__button--container {
    display: flex !important;
  }

  .products__list {
    flex-direction: column !important;
  }

  .products__header {
    max-height: unset !important;
    height: fit-content !important;
    padding: 40px 0px !important;
    margin-bottom: 0px !important;
    margin-top: 20px !important ;
  }

  .products {
    height: fit-content !important;
  }


  .footer__credits--list {
    margin-left: 20px !important;
  }
  .f_displayFlex {
    font-size: 12px !important;
    text-align: center !important;
    width: 100px !important;
  }

  .notfound__title {
    font-size: 60px !important;
  }

 .card__button{
    width: 10px !important;
  }

  .home__button{
    width: 125px !important;
  }
  .infoMenu__BigText{
    font-size: 2rem !important;
  }
  .infoMenu__para{
    font-size: 1.5rem !important; 
  }

  @media (max-height: 811px) {
    
    .home__title {
      font-size: 1.5rem !important;
    }
  
    .home__para {
      font-size: 1.3rem !important;
    }
  
  }
  
  @media (max-height: 711px) {
    .home__title {
      font-size: 1.3rem !important;
    }
  
    .home__para {
      font-size: 1rem !important;
    }
  
  }
}

