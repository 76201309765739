.footer {
  width: 100vw;
  height: 80px;
  background-color: #101010;
  display: flex;
  align-items: center;
}

.footer__container {
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__credits {
  color: #7dbad2;
  font-size: 14px;
}

.footer__copyright {
  color: #7dbad2;
  font-size: 14px;
  display: flex;
}


.f_displayFlex {
  display: flex;
  align-items: center;
}

.footer__credits--list {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer__credits--item {
  font-size: 12px;
}
