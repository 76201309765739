.products {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 160px);
}

.prow {
  width: calc(0% + 10%);
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 24px;
  align-items: center;
}

.products__header {
  transition: 500ms ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 600px;
  width: 100%;
  background-color: #21354a;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.products__list {
  display: flex;
  gap: 60px;
}
