.home {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 160px);
}

.hrow {
  width: 100%;
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 24px;
  align-items: center;
}
.home__header {
  transition: 500ms ease all;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 600px;
  width: 100%;
  background-color: #21354a;
  border-radius: 25px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.home__half {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
}

.h__half--left {
  flex-direction: column;
}

.home__title {
  text-align: center;
  color: #7dbad2;
  font-size: 2rem;
  font-weight: 900;
  animation: htAnimatedGlow 3s ease-in-out infinite;
  text-shadow: 0px 0px 5px rgba(125, 186, 210, 0.25);
}

.title__animation {
  animation: fadeIn 0.8s ease-in backwards;
}

.home__para {
  color: #395b80;
  text-align: center;
  font-size: 2rem;
  margin-top: 20px;
  text-shadow: 0px 0px 10px #395b80;
  max-width: 100rem;
  letter-spacing: 2px;
  line-height: 30px;
  animation: fadeIn 0.85s ease-in backwards;
}

.home__video {
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  animation: fadeIn 0.8s ease-in backwards;
}

.Text__BigFont{
  font-size: 200%;
}

@keyframes htAnimatedGlow {
  0% {
    text-shadow: 0px 0px 5px rgba(125, 186, 210, 0.25);
  }
  50% {
    text-shadow: 0px 0px 10px rgba(125, 186, 210, 0.75);
  }
  100% {
    text-shadow: 0px 0px 5px rgba(125, 186, 210, 0.25);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInVideo {
  0% {
    height: 0px;
  }
}

.home__button {
  box-shadow: 0px 0px 8px rgba(125, 186, 210, 0.1);
  text-shadow: 0px 0px 8px rgba(125, 186, 210, 0.5);
  width: 125px;
}
.home__button:hover {
  box-shadow: 0px 0px 8px rgba(125, 186, 210, 0.5);
  text-shadow: 0px 0px 8px rgba(125, 186, 210, 0.75);
}

.home__button--text {
  font-size: 16px;
}

.home__button--container {
  display: none;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  margin-left: 1rem;
  animation: fadeIn 0.9s ease-in backwards;
}
